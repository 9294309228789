<template>
  <div>
    <div>
      <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="green" dark v-bind="attrs" class="mt-2 ml-2" v-on="on">
            <v-icon class="mr-2" large>mdi-plus</v-icon>
            Add Report
          </v-btn>
        </template>
        <v-card class="mx-auto" max-width="500">
          <v-toolbar flat color="transparent">
            <v-toolbar-title>Select Report</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="$refs.search.focus()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-toolbar>

          <v-container class="py-0">
            <v-row align="center" justify="start">
              <v-col
                v-for="(selection, i) in selections"
                :key="selection.text"
                class="shrink"
              >
                <v-chip
                  :disabled="loading"
                  close
                  @click:close="selected.splice(i, 1)"
                >
                  <v-icon left v-text="selection.icon"></v-icon>
                  {{ selection.text }}
                </v-chip>
              </v-col>

              <v-col v-if="!allSelected" cols="12">
                <v-text-field
                  ref="search"
                  v-model="search"
                  full-width
                  hide-details
                  label="Search"
                  single-line
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-divider v-if="!allSelected"></v-divider>

          <!-- <v-list>
                    <template v-for="item in categories">
                        <v-list-item
                        v-if="!selected.includes(item)"
                        :key="item.text"
                        :disabled="loading"
                        @click="addReport(item)">
                        <v-list-item-avatar>
                            <v-icon
                            :disabled="loading"
                            v-text="item.icon"
                            ></v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item>
                    </template>
                    </v-list> -->

          <v-container>
            <v-treeview
              selected-color="green"
              :search="search"
              :items="items"
              v-model="packageReports"
              :selection-type="selectionType"
              return-object
            >
              <template v-slot:label="{ item }">
                <a @click="addReport(item)" v-if="item.children.length == 0">{{
                  item.name
                }}</a>
                <b v-else>{{ item.name }}</b>
              </template>
            </v-treeview>
          </v-container>
        </v-card>
      </v-menu>
    </div>
    <div class="grid-widget">
      <smart-widget-grid :layout="layout">
        <smart-widget
          v-for="report in reportsList"
          :key="report.id"
          :slot="report.slot"
          :title="report.text"
          fullscreen
        >
          <v-card
            class="mx-auto"
            max-width="600"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
            <v-toolbar flat color="transparent">
              <v-toolbar-title></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu
                :close-on-content-click="false"
                bottom
                min-width="200px"
                rounded
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on">
                    <v-avatar color="green" size="30">
                      <v-icon dark>mdi-settings</v-icon>
                    </v-avatar>
                  </v-btn>
                </template>
                <v-card>
                  <v-form
                    :ref="report.id + '_form'"
                    v-model="settingsFormModel"
                    lazy-validation
                  >
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-select
                            v-model="filter.industryType"
                            :items="industryTypeOptions"
                            label="Industry Type"
                            item-text="englishName"
                            item-value="id"
                            @change="industryTypeChanged"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12" class="pt-0 mt-0">
                          <v-select
                            class="pt-0 mt-0"
                            v-model="filter.businessSector"
                            :items="businessSectorsOptions"
                            label="Business Sector"
                            no-data-text="no items"
                            item-text="englishName"
                            item-value="id"
                            :disabled="filter.industryType == null"
                            @change="businessSectorChanged"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12" class="pt-0 mt-0">
                          <v-select
                            class="pt-0 mt-0"
                            v-model="filter.businessSubSector"
                            :items="businessSubSectorsOptions"
                            label="Sub-Sector"
                            item-text="englishName"
                            item-value="id"
                            :disabled="filter.businessSector == null"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <!-- <v-row>
                        <v-col cols="12" sm="12" md="12" class="pt-0 mt-0">
                          <v-select
                            class="pt-0 mt-0"
                            v-model="filter.legalStructure"
                            :items="legalStructureOptions"
                            label="Legal Structure"
                          >
                          </v-select>
                        </v-col>
                      </v-row> -->
                      <v-row>
                        <v-col cols="12" sm="12" md="12"> Head Count </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" sm="6" md="6" class="pt-0 mt-0">
                          <v-text-field
                            class="pt-0 mt-0"
                            v-model="filter.headCountFrom"
                            :rules="[rules.schemeBoundryMin]"
                            label="From: "
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" md="6" class="pt-0 mt-0">
                          <v-text-field
                            class="pt-0 mt-0"
                            v-model="filter.headCountTo"
                            label="To: "
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row>
                                   <v-col cols="12" sm="12" md="12" class="pt-0 mt-0" >
                                        <v-radio-group
                                        class="pt-0 mt-0"
                                        v-model="filter.PercentOrAmount" row>
                                            <v-radio
                                                label="Percent"
                                                :value="Number(1)"></v-radio>
                                                <v-radio
                                                label="Amount"
                                                :value="Number(2)"
                                            ></v-radio>

                                        </v-radio-group>
                                    </v-col>
                                 </v-row>
                                 <v-row>
                                   <v-col cols="12" sm="12" md="12">
                                     Amount/Percent Range
                                   </v-col>
                                 </v-row>
                                  <v-row>
                                    <v-col cols="6" sm="6" md="6" class="pt-0 mt-0" >
                                       <v-text-field class="pt-0 mt-0"
                                            :rules ="[rules.Percent_val_rule,rules.amtFrom]"
                                            v-model="filter.amountFrom"
                                            label="From: " >
                                        </v-text-field>
                                    </v-col>
                                   <v-col cols="6" sm="6" md="6" class="pt-0 mt-0" >
                                       <v-text-field class="pt-0 mt-0"
                                            v-model="filter.amountTo"
                                            label="To: " >
                                        </v-text-field>
                                    </v-col>
                                 </v-row> -->
                      <v-row v-if="report.hasTitleLevelFilter">
                        <v-col cols="12" sm="12" md="12" class="pt-0 mt-0">
                          <v-select
                            class="pt-0 mt-0"
                            v-model="filter.title"
                            :items="userJobs"
                            label="Title"
                            no-data-text="no items"
                            item-text="job"
                            item-value="jobId"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-btn
                            color="green darken-1"
                            @click="ApplyFilter(report)"
                            >Apply</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card>
              </v-menu>
              <v-menu
                :close-on-content-click="false"
                bottom
                min-width="200px"
                rounded
                offset-y
                v-if="!isCustomerUser"
              >
                <template v-slot:activator="{ on }">
                  <v-btn class="ml-3" icon small v-on="on">
                    <v-avatar color="green" size="30">
                      <v-icon dark>mdi-download</v-icon>
                    </v-avatar>
                  </v-btn>
                </template>
                <v-card>
                  <v-list>
                    <v-list-item link>
                      <v-list-item-title @click="exportReport(report, 1)"
                        >CSV</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title @click="exportReport(report, 2)"
                        >XLS</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title @click="exportReport(report, 3)"
                        >Image</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
              <v-btn
                class="ml-3"
                @click="validateRemoveReport(report)"
                icon
                small
              >
                <v-avatar color="green" size="30">
                  <v-icon dark>mdi-close</v-icon>
                </v-avatar>
              </v-btn>
            </v-toolbar>
            <zingchart
              :data="report.data"
              :ref="report.name"
              :series="report.series"
              :id="report.id"
            ></zingchart>
            <!-- <apexchart width="380" type="donut" :options="chartOptions" :series="series"></apexchart> -->
            <!-- <apexchart width="380"  :options="report.options" :series="report.series" id="mychart"></apexchart> -->
          </v-card>
        </smart-widget>
        <!-- <smart-widget slot="0" simple>
                <div class="layout-center">
                <h3>Simple Widget</h3>
                <h4>Drag on me</h4>
                </div>
            </smart-widget>
            <smart-widget slot="1" title="Default Widget" sub-title="Drag on me">
                <div class="layout-center">
                <h3>Widget with Header</h3>
                </div>
            </smart-widget>
            <smart-widget slot="2" title="Full Screen" sub-title="Drag on me" fullscreen>
                <div class="layout-center">
                <h3>Make any widget full screen</h3>
                </div>
            </smart-widget>
             <smart-widget slot="4" simple>
                <div class="layout-center">
                <h3>Simple Widget</h3>
                <h4>Drag on me</h4>
                </div>
            </smart-widget> -->
      </smart-widget-grid>
    </div>
    <v-dialog v-model="ValidationDialog" max-width="500px">
      <v-card :loading="loading" loading-text="Loading... Please wait">
        <v-card-title class="text-h3">Are you sure ? </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteReport">Yes</v-btn>
          <v-btn color="green darken-1" text @click="ValidationDialog = false"
            >No</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// var zingchart = require('zingchart/zingchart-es6.min.js');
// import  'zingchart/zingchart.min.js';
// import { validationMixin } from "vuelidate";
import zingchart from "zingchart/es6";
import zingchartVue from "zingchart-vue";
import Guid from "../utils/Guid";
// import ReportsEnum from '../utils/enums.json';
export default {
  // mixins: [validationMixin],
  components: {
    zingchart: zingchartVue,
  },
  computed: {
    allSelected() {
      return this.selected.length === this.items.length;
    },
    categories() {
      const search = this.search.toLowerCase();

      if (!search) return this.items;

      return this.items.filter((item) => {
        const text = item.text.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    selections() {
      const selections = [];

      for (const selection of this.selected) {
        selections.push(selection);
      }

      return selections;
    },

    customerNumber() {
      return this.$store.state.userDetails.subscriptionId;
    },
    surveyYear() {
      return this.$store.getters.surveyYear;
    },
    isCustomerUser() {
      return this.$store.getters.customer != "No Customer";
    },
  },

  watch: {
    selected() {
      this.search = "";
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          width: 380,
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
      userJobs: [],
      rules: {
        schemeBoundryMin: (value) =>
          Number(value) <= Number(this.filter.headCountTo) ||
          "Min cannot be greater than Headcount to",
        Percent_val_rule: (value) =>
          !(this.filter.PercentOrAmount == 1 && value > 100) ||
          "value cannot exceed 100%",
        amtFrom: (value) =>
          Number(value) <= Number(this.filter.amountTo) ||
          "Min cannot be greater than Amount to",
      },
      settingsFormModel: null,
      selectedIndex: null,
      filter: {
        industryType: null,
        businessSector: null,
        businessSubSector: null,
        legalStructure: null,
        headCountFrom: 0,
        headCountTo: 0,
        PercentOrAmount: 1,
        amountFrom: 0,
        amountTo: 0,
        title: null,
      },
      itemToBeDeleted: null,
      ValidationDialog: false,
      selectionType: "leaf",
      packageReports: [],
      industryTypeOptions: [],
      businessSectorsOptions: [],
      businessSubSectorsOptions: [],
      businessSubSectorsAllOptions: [],
      legalStructureOptions: [],
      selectedReport: {
        series: [],
        originalData: [],
      },
      showReportTypeDialog: false,
      counter: 1,
      valid: false,

      slot: "0",
      x: 0,
      y: 0,
      layout: [
        { x: 0, y: 0, w: 4, h: 8, i: "0" },
        // { x: 4, y: 0, w: 4, h: 4, i: "1" },
        // { x: 8, y: 0, w: 4, h: 4, i: "2" }
      ],
      items: [],
      reportsList: [],
      //    items: [
      //   {
      //     text: 'Nature',
      //     icon: 'mdi-nature',
      //   },
      //   {
      //     text: 'Nightlife',
      //     icon: 'mdi-glass-wine',
      //   },
      //   {
      //     text: 'November',
      //     icon: 'mdi-calendar-range',
      //   },
      //   {
      //     text: 'Portland',
      //     icon: 'mdi-map-marker',
      //   },
      //   {
      //     text: 'Biking',
      //     icon: 'mdi-bike',
      //   },
      // ],
      loading: false,
      search: "",
      selected: [],
    };
  },
  methods: {
    // downloadReport(report){
    //   CsvDataService.exportToCsv(report.name + ".csv", report.originalData);
    // },
    exportReport(report, type) {
      switch (type) {
        case 1: // CSV
          zingchart.exec(report.id, "downloadCSV", {
            fn: "result.csv",
          });
          break;
        case 2: // XLS
          zingchart.exec(report.id, "downloadXLS", {
            fn: "result.csv",
          });
          break;
        case 3: //Image
          zingchart.exec(report.id, "saveasimage", {
            filename: "result.pdf",
            download: true,
            filetype: "pdf",
          });
          break;
      }
    },
    validateRemoveReport(report) {
      this.itemToBeDeleted = report;
      this.ValidationDialog = true;
    },
    deleteReport() {
      this.editedIndex = this.reportsList.indexOf(this.itemToBeDeleted);
      this.reportsList.splice(this.editedIndex, 1);
      this.ValidationDialog = false;
    },
    industryTypeChanged() {
      this.businessSectorsOptions = this.businessSectorsAllOptions.filter(
        (b) => b.industryTypeId == this.filter.industryType
      );
    },
    businessSectorChanged() {
      this.businessSubSectorsOptions = this.businessSubSectorsAllOptions.filter(
        (b) => b.businessSectorId == this.filter.businessSector
      );
    },
    next() {
      this.loading = true;

      setTimeout(() => {
        this.search = "";
        this.selected = [];
        this.loading = false;
      }, 2000);
    },

    ApplyFilter(report) {
      if (this.$refs[this.selectedReport.id + "_form"][0].validate()) {
        this.selectedReport = Object.assign({}, report);
        // this.selectedReport.series = [];
        // console.log('filtered this.selectedReport')
        // console.log(this.selectedReport)

        // this.loadReportData(2);
        this.updateReport();
      }
    },
    addReport(item) {
      this.selectedReport = Object.assign({}, item);
      this.selectedIndex = this.reportsList.indexOf(item);
      var guid = new Guid();
      this.selectedReport.generatedReportId = guid.newGuid();

      // this.showReportTypeDialog = true;

      console.log("this.selectedReport");
      console.log(this.selectedReport);

      this.selectedReport.id = "report" + "_" + this.counter;
      this.selectedReport.slot = this.slot;

      console.log("before pusing ...............");
      console.log(this.selectedReport);
      this.LoadReport();
      // this.loadReportData(1);
      this.reportsList.push(this.selectedReport);

      //  console.log('this.selectedReport')
      // console.log(this.selectedReport)

      this.slot = String(parseInt(this.slot) + 1);
      if (this.x == 8) {
        this.x = 0;
        this.y++;
      } else this.x += 4;
      this.layout.push({ x: this.x, y: this.y, w: 4, h: 8, i: this.slot });
      this.counter++;
    },
    getReportsList() {
      this.$axios({
        method: "get",
        url: `${this.$baseURL}/SubscriptionPackage/SubscriptionPackageReports`,
        params: {
          PackageId: this.$store.getters["getSubscriptionPackage"],
        },
      }).then((response) => {
        console.log("response.data.packages");
        console.log(response.data.packages);

        response.data.packages.map((report) => {
          if (report.parentReportId == null) {
            this.items.push({
              text: report.reportName,
              icon: "mdi-map-marker",
              id: report.reportId,
              name: report.reportName,
              reportId: report.reportId,
              hasTitleLevelFilter: report.hasTitleLevelFilter,
              slot: this.slot,
              data: null,
              series: [],
              children: [],
              originalData: [],
            });
          } else {
            var parent = this.items.find((rpt) => {
              return rpt.reportId === report.parentReportId;
            });

            parent.children.push({
              generatedReportId: null,
              reportId: report.reportId,
              id: report.reportId,
              name: report.reportName,
              hasTitleLevelFilter: report.hasTitleLevelFilter,
              text: report.reportName,
              icon: "mdi-map-marker",
              slot: this.slot,
              series: [],
              children: [],
              originalData: [],
            });
          }
        });

        // this.items = response.data.packages.map(report =>(
        //   {
        //     text:report.reportName ,
        //     icon:'mdi-map-marker',
        //     reportId:report.reportId,
        //     slot:this.slot,
        //     series:[],
        //   }));
      });
    },

    LoadReport() {
      // var guid = new Guid();
      // this.selectedReport.name =  guid.newGuid();
      this.selectedReport.type = "donut";
      this.selectedReport.data = {
        scale: {
          "size-factor": 0.5,
        },
        noData: {
          text: "Currently there is no data in the chart",
          alpha: 0.6,
          backgroundColor: "#20b2db",
          bold: true,
          fontSize: 18,
          textAlpha: 0.9,

          // topState: {
          //   backgroundColor: 'red',
          //   offsetX: 10,
          //   offsetY: 10,
          // }
        },
        type: "pie",
        // width:"100%",
        plot: {
          borderColor: "#2B313B",
          borderWidth: 5,
          // slice: 90,
          valueBox: {
            placement: "out",
            text: "%t\n%npv%",
            fontFamily: "Open Sans",
            fontSize: "12",
          },

          tooltip: {
            fontSize: "10",
            fontFamily: "Open Sans",
            padding: "2 2",
            text: " %data-tooltip",
          },
          animation: {
            effect: 2,
            method: 5,
            speed: 900,
            sequence: 1,
            delay: 3000,
          },
        },
        source: {
          text: "gs.statcounter.com",
          fontColor: "#8e99a9",
          fontFamily: "Open Sans",
        },
        title: {
          fontColor: "#8e99a9",
          text: this.selectedReport.text,
          align: "left",
          offsetX: 10,
          fontFamily: "Open Sans",
          fontSize: 25,
        },
        plotarea: {
          margin: "0 0 0 0",
        },
      };
      this.$axios({
        method: "get",
        url: `${this.$baseURL}/CustomerDashboard/Report`,
        params: {
          IndustryTypeId: this.filter.industryType,
          BusinessSectorId: this.filter.businessSector,
          BusinessSubSectorId: this.filter.businessSubSector,
          LegalStructureId: this.filter.legalStructure,
          ReportId: this.selectedReport.reportId,
        },
      }).then((response) => {
        console.log("response.data");
        console.log(response);

        //1- count number of items
        var count = 0;
        for (var key in response.data.reportdata) {
          // console.log(key + " -> " + response.data.reportdata[key]);
          count += Number(response.data.reportdata[key]);
          this.selectedReport.originalData.push({
            "Head Count": key,
            "Number of Companies": response.data.reportdata[key],
          });
        }
        //2- get percent per item
        var percent = Number(100 / count).toFixed(2);

        this.$nextTick().then(() => {
          //3- Create series and add to result
          var localSeries = [];
          for (var key in response.data.reportdata) {
            // console.log(key + " -> " + response.data.reportdata[key]);
            localSeries.push({
              "data-tooltip":
                "( Companies: " + response.data.reportdata[key] + ")",
              values: [response.data.reportdata[key] * percent],
              text: key, //+ "( Companies: " + response.data.reportdata[key] +")",
              backgroundColor:
                "#" + Math.floor(Math.random() * 16444215).toString(16),
            });
          }

          //  this.$refs[this.selectedReport.name].series =
          this.selectedReport.series = localSeries;

          console.log("this.selectedReport");
          console.log(this.selectedReport);

          //  zingchart.render({
          //     id: this.selectedReport.id,
          //     data: this.selectedReport.data,
          //     height: '100%',
          //     width: '100%'
          //   });
        });
      });

      this.$forceUpdate();
    },
    updateReport() {
      this.selectedReport.type = "line";
      this.selectedReport.data = {
        type: "pie",
        noData: {
          text: "Currently there is no data in the chart",
          alpha: 0.6,
          backgroundColor: "#20b2db",
          bold: true,
          fontSize: 18,
          textAlpha: 0.9,

          // topState: {
          //   backgroundColor: 'red',
          //   offsetX: 10,
          //   offsetY: 10,
          // }
        },
        plot: {
          borderColor: "#2B313B",
          borderWidth: 5,
          // slice: 90,
          valueBox: {
            placement: "out",
            text: "%t\n%npv%",
            fontFamily: "Open Sans",
          },
          tooltip: {
            fontSize: "18",
            fontFamily: "Open Sans",
            padding: "2 2",
            text: "%npv%",
          },
          animation: {
            effect: 2,
            method: 5,
            speed: 900,
            sequence: 1,
            delay: 3000,
          },
        },
        source: {
          text: "gs.statcounter.com",
          fontColor: "#8e99a9",
          fontFamily: "Open Sans",
        },
        title: {
          fontColor: "#8e99a9",
          text: this.selectedReport.text,
          align: "left",
          offsetX: 10,
          fontFamily: "Open Sans",
          fontSize: 25,
        },
        plotarea: {
          margin: "0 0 0 0",
        },
      };
      this.$axios({
        method: "get",
        url: `${this.$baseURL}/CustomerDashboard/Report`,
        params: {
          IndustryTypeId: this.filter.industryType,
          BusinessSectorId: this.filter.businessSector,
          BusinessSubSectorId: this.filter.businessSubSector,
          LegalStructureId: this.filter.legalStructure,
          headCountFrom: this.filter.headCountFrom,
          headCountTo: this.filter.headCountTo,
          PercentOrAmount: this.filter.PercentOrAmount,
          amountFrom: this.filter.amountFrom,
          amountTo: this.filter.amountTo,
          title: this.filter.title,
          ReportId: this.selectedReport.reportId,
        },
      }).then((response) => {
        console.log("filter response .................");
        console.log(response);

        //1- count number of items
        this.selectedReport.originalData = [];
        var count = 0;
        for (var key in response.data.reportdata) {
          // console.log(key + " -> " + response.data.reportdata[key]);
          count += Number(response.data.reportdata[key]);
          this.selectedReport.originalData.push({
            "Head Count": key,
            "Number of Companies": response.data.reportdata[key],
          });
        }
        //2- get percent per item
        var percent = Number(100 / count).toFixed(2);

        this.$nextTick().then(() => {
          //3- Create series and add to result
          var localSeries = [];
          for (var key in response.data.reportdata) {
            // console.log(key + " -> " + response.data.reportdata[key]);
            localSeries.push({
              values: [response.data.reportdata[key] * percent],
              text: key + "( Companies: " + response.data.reportdata[key] + ")",
              backgroundColor:
                "#" + Math.floor(Math.random() * 16444215).toString(16),
            });
          }

          //  this.$refs[this.selectedReport.name].series =
          this.selectedReport.series = localSeries;
          this.selectedReport.data.series = localSeries;
          console.log("this.selectedReport");
          console.log(this.selectedReport);
          this.reportsList[this.selectedIndex] = this.selectedReport;

          // const app = document.getElementById(this.selectedReport.id);
          console.log("updated series");
          console.log(this.selectedReport.series);

          // zingchart.exec(this.selectedReport.id,"clear")
          // zingchart.exec(this.selectedReport.id,"appendseriesdata",{
          //   data:this.selectedReport.series
          // })
          // zingchart.exec(this.selectedReport.id,"modify",{
          //                                         'object': 'series',
          //                                         data:this.selectedReport.series
          //                                       })

          // zingchart.exec(this.selectedReport.id,"resetseriesdata",{
          //                                         data:this.selectedReport.series
          //                                       })

          zingchart.exec(this.selectedReport.id, "setseriesdata", {
            data: this.selectedReport.series,
          });

          //export.......................................................................
          // zingchart.exec(this.selectedReport.id,"downloadCSV",{
          //                                         fn:'result.csv'
          //                                       });
          // zingchart.exec(this.selectedReport.id,"downloadXLS",{
          // fn:'result.csv'
          //       });
          //  zingchart.exec(this.selectedReport.id,"saveasimage",{
          // filename:'result.pdf',
          // download: true,
          // filetype:'pdf'
          //       });
          //........................................................................

          // zingchart.exec(this.selectedReport.id,"reload")

          //  zingchart.render({
          //     // id: this.selectedReport.name,
          //     id: this.selectedReport.id,
          //     data: this.selectedReport,
          //     height: '100%',
          //     width: '100%'
          //   });
        });
      });
      this.$forceUpdate();
    },

    loadFilterLookups() {
      this.$axios({
        method: "get",
        url: `${this.$baseURL}/CustomerProfile/lookups`,
      }).then((response) => {
        this.$store.commit(
          "updateLegalStructures",
          response.data.legalStructures.map((v) => ({
            value: v.id,
            text: v.englishName,
          }))
        );
        // this.legalStructureOptions = response.data.legalStructures.map((v) => ({
        //   value: v.id,
        //   text: v.englishName,
        // }));
        this.industryTypeOptions = response.data.industryTypes;
        this.businessSectorsAllOptions = response.data.businessSectors;
        this.businessSubSectorsAllOptions = response.data.businessSubSectors;
      });

      this.$axios({
        method: "get",
        url: `${this.$baseURL}/JobTitle/userJob/lookup`,
        params: {
          CustomerNumber: this.customerNumber,
        },
      }).then((_response) => {
        this.userJobs = _response.data.result;
      });
    },
  },

  mounted() {
    this.getReportsList();
    this.loadFilterLookups();
  },
};
</script>

<style>
</style>